import {Component, HostListener, Input, OnInit} from '@angular/core';
import {Brick} from '../brick/brick';
import {Board} from "./board";

@Component({
  selector: 'app-board',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.css']
})
export class BoardComponent implements OnInit {

  @Input() board: Board;

  @Input() width: number;

  private swipeCoord?: [number, number];
  private swipeTime?: number;

  constructor() {}

  ngOnInit(): void {}

  get pieceSize(): number {
    return this.width / this.board.cols;
  }

  get style(): any {
    return {
      width: `${this.pieceSize * this.board.cols}px`,
      height: `${this.pieceSize * this.board.rows}px`,
    };
  }

  get overlayStyle(): any {
    return Object.assign(this.style, {
      'font-size': `${this.width*0.1}px`
    });
  }

  brickStyle(brick: Brick): any {
    return {
      position: 'absolute',
      top: `${this.pieceSize * brick.y}px`,
      left: `${this.pieceSize * brick.x}px`,
    };
  }

  @HostListener('document:keydown', ['$event'])
  onKey(ev: KeyboardEvent): boolean {
    return this.board.handleKey(ev.key);
  }

  swipe(e: TouchEvent, action: 'start'|'end'): boolean {
    console.log(`swipe ${action}`, e);
    const coord: [number, number] = [e.changedTouches[0].clientX, e.changedTouches[0].clientY];
    const time = new Date().getTime();
    if (action === 'start') {
      this.swipeCoord = coord;
      this.swipeTime = time;
    } else {
      const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
      const duration = time - this.swipeTime;

      if (duration < 1500) {
        const len = Math.sqrt(direction.reduce((r, v) => r + v * v, 0));
        if ( Math.abs(direction[0]) > this.pieceSize // Long enough
          && Math.abs(direction[0]) > Math.abs(direction[1] * 3))
        { // Horizontal enough
          const swipe = direction[0] < 0 ? 'ArrowLeft' : 'ArrowRight';
          this.board.handleKey(swipe);
          if (len > this.pieceSize * 2) {
            this.board.handleKey(swipe);
          }
          if (len > this.pieceSize * 3) {
            this.board.handleKey(swipe);
          }
          return false;
        }
        if ( Math.abs(direction[1]) > 30 // Long enough
          && Math.abs(direction[1]) > Math.abs(direction[0] * 3))
        { // Horizontal enough
          const swipe = direction[1] < 0 ? 'ArrowUp' : 'ArrowDown';
          this.board.handleKey(swipe);
          if(direction[1] < 0) {
            if (len > this.pieceSize * 2) {
              this.board.handleKey(swipe);
            }
            if (len > this.pieceSize * 3) {
              this.board.handleKey(swipe);
            }
          }
          return false;
        }
        if (len < 30) {
          this.board.handleKey('ArrowUp');
          return false;
        }

      }
    }
    return true;
  }
}
