import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {Board} from "./board/board";
import {Options} from "./options/options";
import {MultiTetris} from "./multi-tetris";
import {GengineClient, GameComponent} from "verliba-shared";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild(GameComponent)
  game: GameComponent;
  wasInited = false;

  title = 'tetris';

  options = new Options();
  board = new Board(this.options);
  board2 = new Board(this.options, true);
  client = new GengineClient();
  multi = new MultiTetris(this.client, this.board, this.board2);

  ngOnInit(): void {
  }

  penalise(board: Board, event: string) {
    const count = parseInt(event.substr('removed '.length));
    if(count > 1) {
      board.penalise(count-1);
    }
  }

  get aspectRatio() {
    return this.board.cols / this.board.rows * (this.options.two ? 2.2 : 1);
  }

  get width() {
    if(this.options.two) {
      return this.game.gameWidth / 2.2;
    }
    else return this.game.gameWidth;
  }

  ngAfterViewInit(): void {
    setTimeout(()=> this.wasInited = true, 100);
    this.options.events.subscribe((e) => {
      if(e == 'loaded' && this.options.two) {
        console.log(`two`);
        if(this.options.remote) {
          setTimeout(async ()=> {
            this.multi.engage();
            // await this.multi.connect();
          });
        } else {
          this.board.events.subscribe(event => {
            if (event.startsWith('removed ')) {
              this.penalise(this.board2, event);
            }
          });
          this.board2.events.subscribe(event => {
            if (event.startsWith('removed ')) {
              this.penalise(this.board, event);
            }
          });
        }
      }
      this.board.restart();
      if(this.options.two) {
        this.board2.restart();
      }
    });
  }
}
