import {GameRoom, GengineClient, IRemoteGame, Player} from 'verliba-shared';
import {Board} from "./board/board";
import {Serializer} from "./serializer";

export class MultiTetris implements IRemoteGame {

  room?: GameRoom;
  player: Player;
  name = 'tetris';
  // noinspection JSUnusedGlobalSymbols
  roomSelect: boolean = false;

  constructor(
    private client: GengineClient,
    private board1: Board,
    private board2: Board) {
  }

  // noinspection JSUnusedGlobalSymbols
  async leaveGame(): Promise<void> {
    this.board1.pause();
    this.board2.pause();
    this.started = false;
  }

  async startGame(): Promise<void> {
    this.started = true;
  }

  setPlayer(value: Player): void {
    this.player = value;
  }

  started = false;

  engage() {
    this.board1.wait();
    this.board2.wait();
  }

  async joinRoom() {
    const room = await this.client.enterRoom('default');
    if(room) {
      this.room = room;
      room.setUserLimits(2,2);
      room.events.subscribe(this.onRoomEvent.bind(this));
      room.updates.subscribe(this.onRoomUpdate.bind(this));
      room.command.subscribe(this.onCommand.bind(this));
    }
  }

  async onRoomEvent(event: string) {
    try {
      switch (event) {
        case 'enough_users':
          console.log(`got all users`);
          if (this.room.master) {
            this.startMaster().catch();
          }
          break;
        case 'game_start':
          this.startSlave();
          break;
      }
    } catch (e) {
      console.error(e);
    }
  }

  async startMaster() {
    this.room.data = {
      boards: {
        master: Serializer.boardData(this.board1),
        slave: Serializer.boardData(this.board1),
      }
    };
    await this.client.startGame(this.room.data);
    this.openBoards('master');
  }

  get myBoard() : Board {
    return this.board1;
  }
  get theirBoard() : Board {
    return this.board2;
  }
  get masterBoard() : Board {
    return this.room.master ? this.myBoard : this.theirBoard;
  }
  get slaveBoard() : Board {
    return this.room.master ? this.theirBoard: this.myBoard;
  }

  private startSlave() {
    console.log(`starting as slave`);
    const data = this.room.data;
    Serializer.setBoard(this.masterBoard, data.boards.master);
    Serializer.setBoard(this.slaveBoard, data.boards.slave);

    this.openBoards('slave');
  }

  async onBoardEvent(board: Board, event: string, path: string) {
    try {
      // console.log(`board event ${event}`);
      switch (event) {
        case 'brick':
        case 'move':
          await this.client.update(`${path}/falling`, Serializer.brickData(board.falling));
          break;
        case 'over':
          await this.client.update(`${path}/over`, board.score.score);
          break;
        case 'keep':
          await this.client.update(`${path}/pieces`, Serializer.piecesData(board.pieces));
          await this.client.update(`${path}/score`, board.score.score);
          break;
        case 'pause':
          await this.client.command('pause', this.myBoard.paused);
          break;
      }
      if (event.startsWith('removed ')) {
        const count = parseInt(event.substr('removed '.length));
        if(count > 1) {
          await this.client.command('penalise', count);
        }
      }
    } catch(e) {
      console.error(e);
    }
  }

  openBoards(otherName: string) {
    this.myBoard.events.subscribe((event) => {
      this.onBoardEvent(this.myBoard, event, `boards/${otherName}`).catch();
    });

    this.myBoard.waiting = false;
    this.theirBoard.waiting = false;
    this.myBoard.pause();
    this.theirBoard.paused = false;
    this.theirBoard.slave = true;
  }
  private onRoomUpdate(data : {path: string, value: any }) {
    // console.log('room update');
    const board = this.board2;
    const goodBoard = this.room.master ? 'slave' : 'master';
    const names = data.path.split('/');

    if(data.path.startsWith(`boards/${goodBoard}`)) {
      names.splice(0,2);
      switch (names[0]) {
        case 'falling':
          board.falling = Serializer.unpackBrick(data.value);
          break;
        case 'pieces':
          Serializer.setPieces(board, data.value);
          break;
        case 'score':
          board.score.score = data.value;
          break;
        case 'over':
          board.over = data.value;
          break;
      }

    }
  }
  private onCommand(data: {command: string, value: any}) {
    switch (data.command) {
      case 'penalise':
        this.myBoard.penalise(data.value);
        break;
      case 'pause':
        if(this.myBoard.paused !== data.value) {
          this.myBoard.pause()
        }
        this.theirBoard.paused = data.value;
    }
  }
}
