import {Subject} from "rxjs";

export class Options {
  events = new Subject<string>();
  get startDelay(): number {
    return 10 * Math.pow(1.3, this.slow);
  }
  remote: boolean;
  slow = 20;
  showNext = true;
  shadow =  true;
  stickStep = true;
  rotShift = true;
  two = false;
  penalise = false;
}
