import {Piece} from "../piece/piece";

export class Brick {
  pieces: Piece[] = [];
  x = 0;
  y = 0;

  constructor(type = 0) {
    switch (type) {
      case 1: this.setPieces([[-1, 0], [0, 0], [1, 0], [2, 0]], type); break;
      case 2: this.setPieces([[-1, -1], [0, -1], [1, 0], [0, 0]], type); break;
      case 3: this.setPieces([[-1, 0], [-1, -1], [0, 0], [1, 0]], type); break;
      case 4: this.setPieces([[-1, 0], [0, 0], [0, -1], [1, -1]], type); break;
      case 5: this.setPieces([[-1, 0], [0, 0], [1, 0], [1, -1]], type); break;
      case 6: this.setPieces([[-1, 0], [-1, -1], [0, -1], [0, 0]], type); break;
      case 7: this.setPieces([[-1, -1], [0, -1], [1, -1], [0, 0]], type); break;
    }
  }

  setPieces(coordinates: [number, number][], fill: number): void {
    this.pieces = coordinates.map((xy) => new Piece(xy[0], xy[1]));
    this.pieces.forEach(piece => piece.fill = fill);
  }

  isIn(dx: number, dy: number, rotate: boolean, width: number, height: number): boolean {
    return this.pieces.every(piece =>
      piece.isIn(this.x + dx, this.y + dy, rotate, this.cw, width, height));
  }

  move(dx: number, dy: number, rotate = false): void {
    this.x += dx;
    this.y += dy;
    if (rotate) {
      for(const piece of this.pieces){
        piece.rotate(this.cw);
      }
    }
  }
  get cw(): boolean { return this.pieces[0].fill !== 5; }

}
