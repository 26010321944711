<div fxLayout="column">
  <form>
    <div class="options" [style]="style" fxLayout="row">
      <div fxLayout="column">
      <mat-slider [(ngModel)]="options.slow" name="slow" (change)="onChange()" [thumbLabel]="true"
                  [min]="5" [max]="30" [step]="1"></mat-slider>
      <mat-checkbox [(ngModel)]="options.shadow" name="shadow" (change)="onChange()">Shadow</mat-checkbox>
      </div>
      <div fxLayout="column">
        <mat-checkbox [(ngModel)]="options.showNext" name="showNext" (change)="onChange()">Next</mat-checkbox>
        <mat-checkbox [(ngModel)]="options.rotShift" name="rotShift" (change)="onChange()">Easy rotate</mat-checkbox>
        <mat-checkbox [(ngModel)]="options.stickStep" name="stickStep" (change)="onChange()">Wait down</mat-checkbox>
      </div>
      <div fxLayout="column">
        <mat-checkbox [(ngModel)]="options.two" name="two" (change)="onChange()">2-players</mat-checkbox>
        <mat-checkbox [(ngModel)]="options.remote" name="remote" (change)="onChange()">remote</mat-checkbox>
        <mat-checkbox [(ngModel)]="options.penalise" name="penalise" (change)="onChange()">Penalisation</mat-checkbox>
      </div>
    </div>
  </form>
</div>
