<app-game [aspectRatio]="aspectRatio" [heightPart]="0.8" [widthPart]="0.9">
  <div *ngIf="wasInited">
    <app-gengine-client *ngIf="options.two && options.remote" [client]="client" [game]="multi"></app-gengine-client>
    <div fxLayout="row">
      <div fxFlex></div>
      <app-player *ngIf="options.two" [width]="width" [board]="board2" [options]="options"></app-player>
      <div *ngIf="options.two" fxFlex></div>
      <app-player [width]="width" [board]="board" [options]="options"></app-player>
      <div fxFlex></div>
    </div>
    <app-options [width]="game.gameWidth" [options]="options"></app-options>
  </div>
  <router-outlet></router-outlet>
</app-game>
