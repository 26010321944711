import {Component, Input, OnInit, EventEmitter, Output, OnDestroy} from '@angular/core';
import {Options} from "../options/options";
import {Score} from "./score";

@Component({
  selector: 'app-tools',
  templateUrl: './tools.component.html',
  styleUrls: ['./tools.component.css']
})
export class ToolsComponent implements OnInit, OnDestroy {

  @Input() width = 400;
  @Input() options: Options;
  @Input() score: Score;

  @Output() action = new EventEmitter<string>();

  timer: any;

  constructor() { }

  ngOnInit(): void {
    this.timer = setInterval(() => this.score.move(), 200);
  }

  get scoreStyle(): any {
    return {
      'font-size': `${this.width/10}px`,
      margin: `${this.width/30}px`
    }
  }

  getAddStyle(a: any): any {
    return {
      right: `${-(3 + a.age) * this.width / 60}px`,
      top: `${-a.age * this.width / 80}px`,
      'font-size': `${this.width / 5 / (a.age + 4)}px`,
      opacity: `${100 - 10 * a.age}%`
    }
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }

  restart(): void { this.action.emit('restart'); }
  pause(): void { this.action.emit('pause'); }

  get style(): any {
    return {
      width: `${this.width}px`,
      height: `${this.width * 0.15  }px`,
      margin: `${this.width*0.01}px auto auto`

    };
  }
  get pieceSize(): number {
    return this.width / 25;
  }

  get nextStyle(): any {
    return {
      position: 'absolute',
      top: `${this.pieceSize * 2}px`,
      right: `${this.pieceSize * 3}px`,
    };
  }

  get buttonStyle(): any {
    return {
      'line-height': `${3*this.pieceSize}px`
    }
  }

  get iconStyle(): any {
    return {
      'font-size': `${2*this.pieceSize}px`
    }
  }

}
