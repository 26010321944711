import {Component, Input, OnInit} from '@angular/core';
import {Piece} from "./piece";

@Component({
  selector: 'app-piece',
  templateUrl: './piece.component.html',
  styleUrls: ['./piece.component.css']
})
export class PieceComponent implements OnInit {

  @Input()
  piece: Piece;

  @Input()
  size = 30;

  @Input()
  shadow = false;

  constructor() { }

  ngOnInit(): void {
  }

  get style(): any {
    const colors = ['#0000000f', '#56a14f', '#11a190', '#a16139', '#4563a1', '#9c52a1', '#90a140', '#a12d28', "#707070"];
    return {
      'background-color': colors[this.piece.fill % colors.length],
      width: `${this.size}px`,
      height: `${this.size}px`
    };
  }
}
