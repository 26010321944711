import {AfterViewInit, Component, Input} from '@angular/core';
import {Options} from "./options";
import * as _ from 'lodash';

@Component({
  selector: 'app-options',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.css']
})
export class OptionsComponent implements AfterViewInit {
  @Input() options: Options;
  @Input() width = 500;

  constructor() {
  }

  ngAfterViewInit(): void {
    setTimeout(()=> {
      this.load();
      }, 100);
  }

  onChange(): void {
    this.save();
  }

  get style(): any {
    return {
      width: `${this.width}px`,
      height: `${this.width * 0.2}px`
    };
  }

  save() {
    const clone = _.clone(this.options);
    delete clone.events;
    delete clone.startDelay;

    localStorage.setItem('tetrisOptions', JSON.stringify(clone));
  }

  load() {
    const options = JSON.parse(localStorage.getItem('tetrisOptions') || '{}');
    delete options.startDelay;
    Object.assign(this.options, options);
    this.options.events.next('loaded');
  }

}
