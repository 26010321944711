import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Board} from "../board/board";
import {ToolsComponent} from "../tools/tools.component";
import {Options} from "../options/options";

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.css']
})
export class PlayerComponent implements OnInit {
  @Input() width = 300;
  @Input() board: Board;
  @Input() options: Options;

  @ViewChild(ToolsComponent)
  tools: ToolsComponent;

  constructor() { }

  ngOnInit(): void {
  }

  toolsAction(action: string): void {
    switch(action) {
      case 'restart': this.board.restart(); break;
      case 'pause': this.board.pause(); break;
    }
  }
}
