import {Brick} from "../brick/brick";
import {Piece} from "../piece/piece";
import {Subject} from "rxjs";
import {Options} from "../options/options";
import {Score} from "../tools/score";

export class Board {
  pieces: Piece[][] = [];
  rows = 24;
  cols = 12;
  falling: Brick = null;
  shadow: Brick = null;
  next: Brick = null;
  score = new Score();

  timer: any;
  startDelay = 500;
  delay = 0;
  bricks = 0;
  events = new Subject<string>();
  over = false;
  paused = false;

  private stalled = false;
  doShadow =  true;
  stickStep = true;
  rotShift = true;
  waiting = false;
  slave = false;

  constructor(private options: Options, private player2 = false) {
    this.events.subscribe((event) => {
      switch (event) {
        case 'start': this.score.onStart();break;
        case 'bingo': this.score.onBingo(); break;
        case 'removed 1': this.score.onLines(1); break;
        case 'removed 2': this.score.onLines(2); break;
        case 'removed 3': this.score.onLines(3); break;
        case 'removed 4': this.score.onLines(4); break;
        case 'brick': this.score.onNext(this.next); break;
      }
    });
  }

  fall(dx: number, dy: number): void {
    if (!this.falling) {
      const brick = this.next;
      this.next = new Brick(Math.round(1 + Math.random() * 6));
      if(!brick) return;
      brick.x = Math.round(this.cols / 2);
      brick.y = -2;
      if (!(this.bricks % 20)) {
        this.delay = this.startDelay * Math.pow(0.9 , this.bricks/20);
        this.setSpeed();
      }
      this.bricks ++;
      while (!brick.isIn(0, 0, false, this.cols, this.rows)) {
        brick.move(0, 1);
      }
      if (!this.checkCollision(brick, 0, 0, false)) {
        clearInterval(this.timer);
        this.over = true;
        this.events.next('over');
      } else {
        this.falling = brick;
        this.events.next('brick');
        this.shadow = new Brick(brick.pieces[0].fill);
        this.shadow.x = brick.x;
        this.shadow.y = brick.y;
        dy = 0;
      }
    }
    if (!this.move(dx, dy)) {
      if (!this.stalled && this.stickStep) {
        this.stalled = true;
      } else {
        this.keep();
      }
    } else {
      this.stalled = false;
    }
  }

  checkCollision(brick: Brick, dx: number, dy: number, rotate: boolean): boolean {
    return brick.pieces.every(piece => {
      const rotated = piece.rotated(rotate, brick.cw);
      const y = brick.y + rotated.dy + dy;
      const x = brick.x + rotated.dx + dx;
      return this.pieces[y][x].fill === 0;
    });
  }

  keep(): void {
    if(!this.falling) { return; }
    const ysMap: {[y: number]: boolean } = {};
    this.falling.pieces.forEach(piece => {
      const y = this.falling.y + piece.dy;
      const x = this.falling.x + piece.dx;
      this.pieces[y][x].fill = piece.fill;
      ysMap[y] = true;
    });
    const ys = Object.keys(ysMap).map(y => Number.parseInt(y, 10));
    let lines = 0;
    for (const y of ys) {
      if (this.pieces[y].every(piece => piece.fill !== 0)) {
        this.remove(y);
        lines ++;
      }
    }
    if (ys.every( y => y >= this.rows - 4)) {
      if (this.pieces.every(row => row.every(piece => piece.fill === 0))) {
        this.events.next('bingo');
        console.log('BINGO!!');
      }
    }
    if (lines > 0) {
      this.events.next(`removed ${lines}`);
    }
    this.falling = null;
    this.shadow = null;
    this.events.next('keep');
  }

  remove(y: number): void {
    this.pieces.splice(y, 1);
    this.pieces.splice(0, 0, new Array(this.cols).fill(0).map(() => new Piece(0, 0)));
  }

  fallDown(): void {
    while (this.move(0, 1, false)) {}
  }

  handleKey(key: string): boolean {
    if(this.waiting) return true;
    if(this.slave) return true;
    let used = false;
    if(!this.player2) {
      switch (key) {
        case 'ArrowLeft' :
          this.move(-1, 0);
          used = true;
          break;
        case 'ArrowRight' :
          this.move(1, 0);
          used = true;
          break;
        case 'ArrowUp':
          this.move(0, 0, true);
          used = true;
          break;
        case 'ArrowDown':
          this.fallDown();
          used = true;
          break;
      }
    } else {
      switch (key) {
        case 'a' :
          this.move(-1, 0);
          used = true;
          break;
        case 'd' :
          this.move(1, 0);
          used = true;
          break;
        case 'w':
          this.move(0, 0, true);
          used = true;
          break;
        case 's':
          this.fallDown();
          used = true;
          break;
      }
    }
    switch (key) {
      case 'Enter':
        this.restart();
        used = true;
        break;
      case ' ':
        this.pause();
        used = true;
        break;
    }
    return !used;
  }

  pause() {
    if(this.waiting) return;
    if(this.timer) {
      clearInterval(this.timer);
      this.timer = null;
      this.paused = true;
      this.events.next('pause');
    } else {
      this.setSpeed();
      this.paused = false;
      this.events.next('pause');
    }
  }

  restart(): void {
    if(this.waiting) return;
    this.startDelay = this.options.startDelay;
    this.doShadow = this.options.shadow;
    this.rotShift = this.options.rotShift;
    this.stickStep = this.options.stickStep;
    this.over = false;
    this.paused = false;

    this.bricks = 0;
    this.falling = null;
    this.shadow = null;
    this.stalled = false;
    this.delay = this.startDelay;
    this.pieces = new Array(this.rows).fill(0)
      .map(() => new Array(this.cols).fill(0)
        .map(() => new Piece()));
    this.setSpeed();
    this.events.next('start');
  }

  setSpeed(): void {
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.timer = setInterval(
      () => this.fall(0, 1),
      this.delay);
  }

  move(dx: number, dy: number, rotate = false): boolean {
    if(this.over) return false;
    const res = this.moveBrick(this.falling, dx, dy, rotate);
    if(rotate && res) {
      this.shadow.move(0,0, rotate);
    }
    if(this.falling && this.shadow) {
      this.shadow.x = this.falling.x;
      this.shadow.y = this.falling.y;
      while(this.moveBrick(this.shadow, 0,1)) {}
    } else {
      this.shadow = null;
    }
    if(res) {
      this.events.next('move');
    }
    return res;
  }

  moveBrick(brick: Brick, dx: number, dy: number, rotate = false): boolean {
    if (!brick) {
      return false;
    }
    let canDo = brick.isIn(dx, dy, rotate, this.cols, this.rows) &&
      this.checkCollision(brick, dx, dy, rotate);
    if(rotate && !canDo && this.rotShift) {
      let tstDx = 0;
      if(brick.x < 4) {
        tstDx = 1;
      }
      if(brick.x >= this.cols - 4) {
        tstDx = -1
      }
      if(tstDx) {
        canDo = brick.isIn(dx + tstDx, dy, rotate, this.cols, this.rows) &&
          this.checkCollision(brick, dx + tstDx, dy, rotate);
        if(!canDo) {
          canDo = brick.isIn(dx + 2*tstDx, dy, rotate, this.cols, this.rows) &&
            this.checkCollision(brick, dx + 2*tstDx, dy, rotate);
          if(canDo) {
            dx += 2 * tstDx;
          }
        } else {
          dx += tstDx;
        }
      }
    }
    if (canDo) {
      brick.move(dx, dy, rotate);
      return true;
    }
    return false;
  }

  penalise(count: number) {
    if(this.over) {
      return;
    }
    for(let i = 0; i < count; i ++) {
      if(Math.random() >  0.5) { continue; }
      const line = new Array(this.cols).fill(0).map(() => new Piece());
      let filled = 0;
      for(const p of line) {
        if(Math.random() < 0.9 && filled < this.cols -1) {
          p.fill = 8;
          filled ++;
        }
      }
      this.pieces.push(line);
      this.pieces.splice(0,1);
      console.log('penalised');
    }
  }

  wait() {
    if(this.timer) {
      this.pause();
    }
    this.waiting = true;
  }
}
