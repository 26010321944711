export class Piece {
  fill = 0;
  constructor(public dx = 0, public dy = 0) {

  }

  isIn(dx: number, dy: number, rotate: boolean, cw: boolean, width: number, height: number): boolean{
    const rotated = this.rotated(rotate, cw);
    const x = rotated.dx + dx;
    const y = rotated.dy + dy;
    return x >= 0 && y >= 0 && x < width && y < height;
  }

  rotated(rotate: boolean, cw: boolean): {dx: number, dy: number} {

    return rotate
      ? cw
        ? { dy: this.dx, dx: -this. dy}
        : { dy: -this.dx, dx: this. dy}
      : this;
  }

  rotate(cw: boolean): void {
    Object.assign(this, this.rotated(true, cw));
  }

}
