import {Component, Input, OnInit} from '@angular/core';
import {Brick} from "./brick";
import {Piece} from "../piece/piece";

@Component({
  selector: 'app-brick',
  templateUrl: './brick.component.html',
  styleUrls: ['./brick.component.css']
})
export class BrickComponent implements OnInit {

  @Input()
  brick: Brick;

  @Input()
  pieceSize = 30;

  @Input()
  shadow = false;

  constructor() { }

  ngOnInit(): void {
  }

  styleFor(piece: Piece): any {
    return {
      top: `${this.pieceSize * piece.dy}px`,
      left: `${this.pieceSize * piece.dx}px`,
    };
  }

}
