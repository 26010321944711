import {Brick} from "../brick/brick";

export class Score {

  score = 0;
  newScore = 0;
  next: Brick;
  additions: {v: number, age: number}[] = [];

  onStart(): void {
    this.score = 0;
  }

  onBingo(): void {
    this.newScore += 500;
  }

  onLines(count: number): void {
    this.newScore += Math.pow(3, count);
  }

  onNext(brick: Brick): void {
    this.next = brick;
    this.newScore += 1;
  }

  move() {
    const v = this.newScore;
    this.newScore = 0;
    this.score += v;
    const next = v ? [{v,age:0}]: [];
    for(let a of this.additions) {
      if(a.age < 10) {
        a.age ++;
        next.push(a);
      }
    }
    this.additions = next;
  }


}
