import {Piece} from "./piece/piece";
import {Board} from "./board/board";
import {Brick} from "./brick/brick";

export class Serializer
{

  static boardData(board: Board): any {
    return {
      rows: board.rows,
      cols: board.cols,
      startDelay: board.startDelay,
      pieces: this.piecesData(board.pieces),
      falling: this.brickData(board.falling),
      next: this.brickData(board.next),
    };
  }

  static piecesData(pieces: Piece[][]):any {
    return pieces.map(row => row.map(piece => piece.fill));
  }

  static brickData(brick: Brick): any {
    return brick ? {
      x: brick.x,
      y: brick.y,
      pieces: brick.pieces.map(this.pieceData)
    } : null;
  }

  static pieceData(piece: Piece): any {
    return {
      fill: piece.fill,
      dx: piece.dx,
      dy: piece.dy,
    }
  }

  static setBoard(board: Board, data: any) {
    board.rows = data.rows;
    board.cols = data.cols;
    board.startDelay = data.startDelay;

    board.pieces = data.pieces.map(row => row.map(fill => new Piece(fill)));
    board.next = Serializer.unpackBrick(data.next);
  }

  static setPieces(board: Board, data: any) {
    for(let y = 0; y < board.pieces.length; y ++) {
      const row = board.pieces[y];
      for(let x = 0; x < row.length; x ++) {
        row[x].fill = data[y][x];
      }
    }
  }

  static unpackBrick(data: any) : Brick {
    if(!data) return null;
    const res = new Brick();
    res.x = data.x;
    res.y = data.y;
    res.pieces = data.pieces.map(Serializer.unpackPiece);
    return res;
  }

  static unpackPiece(data: any) : Piece {
    if(!data) return null;
    const res = new Piece(data.dx, data.dy);
    res.fill = data.fill;
    return res;
  }


}
