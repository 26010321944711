import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatButtonModule } from "@angular/material/button";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BoardComponent } from './board/board.component';
import { BrickComponent } from './brick/brick.component';
import { PieceComponent } from './piece/piece.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToolsComponent } from './tools/tools.component';
import { MatSliderModule } from "@angular/material/slider";
import { FormsModule } from '@angular/forms';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { OptionsComponent } from './options/options.component';
import { PlayerComponent } from './player/player.component';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { VerlibaSharedModule } from "verliba-shared";

@NgModule({
  declarations: [
    AppComponent,
    BoardComponent,
    BrickComponent,
    PieceComponent,
    ToolsComponent,
    OptionsComponent,
    PlayerComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatSliderModule,
    FormsModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    VerlibaSharedModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
