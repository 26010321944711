<div class="board"
     (touchstart)="swipe($event, 'start')"
     (touchend)="swipe($event, 'end')"
     [style]="style"
>
  <div class="row" fxLayout="row" *ngFor="let row of board.pieces">
    <app-piece *ngFor="let piece of row" [piece]="piece" [size]="pieceSize-4"></app-piece>
  </div>
  <app-brick *ngIf="board.falling"
             [brick]="board.falling"
             [style]="brickStyle(board.falling)"
             [pieceSize]="pieceSize"></app-brick>
  <app-brick *ngIf="board.shadow && board.doShadow"
             [brick]="board.shadow"
             [style]="brickStyle(board.shadow)"
             [pieceSize]="pieceSize"
             [shadow]="true"></app-brick>
  <div class="overlay" [style]="overlayStyle" *ngIf="board.over || board.paused">
    <span *ngIf="board.over">Game over</span>
    <span *ngIf="board.waiting && !board.over">Waiting</span>
    <span *ngIf="board.paused && !board.waiting &&!board.over">Paused</span>
  </div>
</div>
