<div class="tools" [style]="style" fxLayout="row">
  <div class="score" [style]="scoreStyle">{{score.score}}
    <div class="addition" *ngFor="let a of score.additions" [style]="getAddStyle(a)">+{{a.v}}</div>
  </div>
  <div fxFlex></div>
  <button mat-button (click)="restart()" [style]="buttonStyle">
    <span class="material-icons" [style]="iconStyle">refresh</span>
  </button>
  <button mat-button (click)="pause()" [style]="buttonStyle">
    <span class="material-icons" [style]="iconStyle">pause</span>
  </button>
  <div fxFlex></div>
  <div class="next" *ngIf="score.next && options && options.showNext" >
    <app-brick [style]="nextStyle" [brick]="score.next" [pieceSize]="pieceSize"></app-brick>
  </div>
</div>
